@import 'user-bookings';
.booking1 {
    img {
      width: 800px;
    }
}

.booking {
    padding: 20px;
    border: 1px solid $light-gray-color-2;

    a:hover {
      color: white;
    }
  
    .btn-confirm {
      font-size: 17px;
      font-weight: 700;
      padding: 13px;
    }
  
    label {
      font-size:12px;
      font-weight: 300;
      margin-bottom: 0;
    }
  
    &-price {
      font-size: 22px;
      font-weight: 700;
    }
  
    &-per-night {
      font-size: 13px;
      font-weight: 300;
    }
  
    &-note-title {
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    &-note-text {
      font-size: 13px;
      font-weight: 300;
    }
  
    .should-login-btn-container {
      text-align: center;
      margin-bottom: 10px;
  
      .btn-login {
      text-align:center;
      font-size: 15px;
      padding: 15px;
  
      &:hover, &:focus {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  
  
  // MODAL
  .booking-modal {
    .title {
      font-weight: 700;
      font-size: 26px;
    }
  
    .buttons {
      margin: 15px 0px;
    }
  
    em {
      font-style: normal;
      font-size: 17px;
      font-weight: 700;
    }
  
    p {
      margin: 0;
    }
  }
  
  // Calendar booking
  
  .daterangepicker td.in-range {
    background-color: red $main-color;
  }
  
  .daterangepicker td.active {
    background-color: $main-color !important;
  }
  