@import '../variables.scss';

.bwm-form {
    color: $main-text-gray-color;
  
    h1 {
      font-weight: 700;
      margin-bottom: 30px;
    }
    .image-container {
      position: relative;
  
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  
    label {
      font-size: 12px;
    }
  
    .form-text {
      font-size: 13px;
    }
  
    .catchphrase {
      text-align: center;
      font-size: 42px;
      position: absolute;
      color: #f1f1f1;
      padding: 40px;
      background-color: rgba(0,0,0, 0.5);
      margin-top: 50px;
      letter-spacing: 1px;
    }

    .btn-form{
        margin-top: 15px;
    }
  }

  
  .bwm-res-errors {
    margin: 10px 0;
  
    p {
      margin-bottom: 5px;
    }
  }
  