.mini-cart {
  width: 300px;
  .cart__topbar {
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 10px;
    color: white;
    background-color: #f4722d;
    box-shadow: 0 6px 6px -6px rgb(124, 124, 124);
    -webkit-box-shadow: 0 6px 6px rgb(124, 124, 124);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0 6px 6px rgb(124, 124, 124);
    align-items: center;
  }
  .mini-cart__item {
    display: flex;
    padding: 5px;
    height: 70px;
    margin-bottom: 5px;
    margin-top: 5px;

    justify-content: space-between;
    align-items: center;
    transition: background 300ms ease;
    &:hover {
      // background: lightgray ;
    }
    img {
      width: 100px;
      height: 100px;

    }
  }
}

.cart__item {
  display: flex;
  padding: 0px;
  height: 70px;
  margin-bottom: 0px;
  margin-top: 0px;

  justify-content: space-between ;
  align-items: flex-start;
  transition: background 300ms ease;
  &:hover {
    //background: lightgray ;
  }
  img {
    width: 70px;
    height: 80px;
    margin-top: 5px;
  }
}

.order_summary{
  display: flex;
  //padding: 5px;
  //height: 70px;
  //margin-bottom: 5px;
 // margin-top: 5px;

  justify-content: space-between ;
  align-items: center;
  transition: background 300ms ease;
}