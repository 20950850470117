.chat-sidenav {
  border-right: 1px solid $light-gray;
  height: 450px;
  .chat-contact-list {
    height: 100%;
  }
}
.chat-container {
  // background: rgba(0, 0, 0, 0.05);

  height: calc(100vh - 90px);
  .chat-message-list {
    .list__message {
      border-radius: 4px;
      overflow: hidden;
    };
    .right__component{
      position: absolute;
      right: 20px;
    };
    .left__component{
      position: absolute;
      left: 20px;
    }
  }
  .empty-message-circle {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    box-shadow: $elevation-z6;

    .MuiIcon-root {
      font-size: 4rem !important;
    }
  }
}
