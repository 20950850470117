 #rentalListing{
   .title{
     font-size: 30px;
     font-weight: 700;
     margin-bottom: 20px;
   }

   .rental-detail-link{
     &:hover{
       text-decoration: true;
     }
   }
 }
 
 
 /* SINGLE CARD STYLES*/
 .bwm-card {
    border: none;
    margin-bottom: 20px;

    .card-block {
      padding-top: 8px;

      .card-subtitle {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
      }

      .card-title {
        font-size: 19px;
        margin: 3px 0;
        color: $main-text-gray-color;
      }

      .card-text {
        font-size: 14px;
        font-weight: 300;
        color: #7e7e7e;
        margin-bottom: 0;
      }
    }
  }
