body {
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans';
}

.nav-container {
  margin: left;
  background: #1A2038;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}

.app-container {
  padding-left: 140px;
  padding-right: 150px;
  //max-width: 1200px;
  min-height: 900px;
}

@media only screen and (max-width: 450px) {
  .app-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .map{
    width:335px;
    height:500px; 
  }
}

@media only screen and (min-width: 450px) {
  .map{
    width:100%;
    height:600px; 
  }
}

@media only screen and (max-width: 450px) {
  .mapCheckout{
    width:335px;
    height:400px; 
  }
}

@media only screen and (min-width: 450px) {
  .mapCheckout{
    width:100%;
    height:400px; 
  }
}

.card-element {
  border: 0px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 40px;
  padding-top: 7px;
  padding-left: 7px;
  background-color: rgba(0, 0, 0, 0.08)
}

.awssld {
  --content-background-color: #fff !important;
}

.topbar {
  position: relative;
  width: 100%;
  display: table;
  height: 60;
  border-bottom: 0px solid transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 98;
}

.brand {
  height: 100%;
  img {
    height: 30px;
  }
  .brand__text {
    font-weight: 500;
    font-size: 1.5rem;
   // margin: 0 1rem;
  }
}

.derecha   { float: right; }


.analytics {
  .face-group {
    .avatar {
      border: 2px solid white;
      &:not(:first-child) {
        margin-left: -14px;
      }
    }
  }

  .small-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;

    .small-icon {
      font-size: 8px;
    }
  }
}
